
import { defineComponent, computed, ref } from 'vue'
import {
  PageContent,
  useResourceDataState,
  ProfileCard,
  transactionHistoryService,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@bd/agent/store'
import { TransactionHistoryDto } from '@bd/api'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import ProgressSpinner from 'primevue/progressspinner'
import { TransactionDetailsPayload } from '@bd/api/common/types/transaction'

export default defineComponent({
  name: 'ProfileTransactions',
  components: {
    PageContent,
    ProfileCard,
    EmptyState,
    ProgressSpinner,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const errorMessage = ref<string>()

    const checkIfBreak = (
      previous: TransactionHistoryDto | undefined,
      current: TransactionHistoryDto,
    ) => {
      if (!previous) {
        return true
      }
      const previousYear = new Date(previous.date).getFullYear()
      const currentYear = new Date(current.date).getFullYear()
      return previousYear !== currentYear
    }

    const transactions = computed(() => {
      return store.state?.transactionHistory?.transactions.content || []
    })
    const transactionDetails = computed(() => {
      return store.state.transactionHistory?.transactionDetails || []
    })

    const { isLoading, isPresent, isEmpty } = useResourceDataState(
      transactions,
      (res) => !!(res && res.length),
    )

    const fetchTransactions = async () => {
      await store.dispatch('transactionHistory/transactionsList')
    }
    const fetchTransactionDetails = async (
      payload: TransactionDetailsPayload,
    ) => {
      errorMessage.value = undefined
      try {
        await store.dispatch(
          'transactionHistory/transactionDetailsAgent',
          payload,
        )
      } catch (e) {
        const errorsData = transactionHistoryService.handleError(e)
        errorMessage.value = errorsData
      }
    }

    fetchTransactions()
    return {
      t,
      transactions,
      transactionDetails,
      checkIfBreak,
      isLoading,
      isPresent,
      isEmpty,
      fetchTransactionDetails,
      errorMessage,
    }
  },
})
